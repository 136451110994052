.verify {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}
.verify h1 {
    text-transform: uppercase;
}
.verify h3 {
    text-transform: capitalize;
}
.verify .verify-box {
    padding: 5vh 10vw;
    display: flex;  
    flex-direction: column;
    align-items: center;
}
.verify .verify-box .logo {
    max-width: 80%;
    margin-bottom: 1rem;
}
.verify .verify-box .text-field {
    width: 100%;
    margin-top: 1.5rem;
    
}
.verify .verify-box .button {
    width: 100%;
    margin-top: 1rem;
}

@media screen and (max-width: 500px) {
    .verify .verify-box {
    padding: 2rem 1rem;

    }
}
