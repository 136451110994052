.login {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}
.login h1 {
    text-transform: uppercase;
}
.login h3 {
    text-transform: capitalize;
}
.login .login-box {
    padding: 5vh 10vw;
    display: flex;  
    flex-direction: column;
    align-items: center;
}
.login .login-box .logo {
    max-width: 80%;
    margin-bottom: 1rem;
}
.login .login-box .text-field {
    width: 100%;
    margin-top: 1.5rem;
    
}
.login .login-box .button {
    width: 100%;
    margin-top: 1rem;
}

@media screen and (max-width: 500px) {
    .login .login-box {
    padding: 2rem 1rem;

    }
}
