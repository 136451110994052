.header {
    justify-content: space-between;
    align-items: center;
}
.header div {
    display: flex;
    align-items: center;
}
.header .user * {
    /* margin-left: 0.5rem; */
}
